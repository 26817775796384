// to be completed

import moment from 'moment'
import { handleQueryResolve } from '../utils'

export default function(fromDate, toDate) {
    // console.log(fromDate + ' ' + toDate);

    const dateFormat = 'DD/MM/YYYY'
    const fromDateStr = moment(fromDate).format(dateFormat)
    const toDateStr = moment(toDate).format(dateFormat)

    // const fromDate = '03/12/2019';
    // const toDate = '04/12/2019';

    // Fixme: For some reason this doesn't seem to work and give date conversion error
    // Need to figure out how to make this work instead of inserting string value
    // directly into query
    const attributes = []
    const where = []
    if (fromDate) {
        attributes.push({
            param: 'fromDate',
            type: 'sql.VarChar(60)',
            value: 'CONVERT(DATE,\'20191203\',112)',
        })

        attributes.push({
            param: 'toDate',
            type: 'sql.VarChar(60)',
            value: 'CONVERT(DATE,\'20191204\',112)',
        })

        where.push('CONVERT(DATE,Rx.RxDate) BETWEEN @fromDate')
        where.push('@toDate')
    }

    const queryStr = `
  SELECT
  Patient.FirstName,
  Patient.LastName,
  Patient.FirstName + ' ' + Patient.LastName AS PatientName,
  Rx.MixNumber as MixID,
  Rx.PatientID as PatientID,
  MixHeader.MixName as Description,
  SUBSTRING(
    Fillware.dbo.Rx.RXNumber,
    PATINDEX('%[^0]%', Fillware.dbo.Rx.RXNumber + '.'),
    LEN(Fillware.dbo.Rx.RXNumber)
) as RxNum,
  CONVERT(DATE,Rx.RxDate,112) AS FillDate
FROM
Fillware.dbo.Rx
    INNER JOIN Fillware.dbo.MixHeader ON Fillware.dbo.Rx.MixNumber = Fillware.dbo.MixHeader.MixNumber
  INNER JOIN Fillware.dbo.Patient ON Fillware.dbo.Rx.PatientID = Fillware.dbo.Patient.PatientID
WHERE
Rx.MixNumber > 0
 AND CONVERT(DATE,Rx.RxDate,112) =  '${fromDate}'
ORDER BY
RxDate DESC
      `

    // console.log(queryStr);
    return this.query(queryStr, attributes).then(handleQueryResolve)
}